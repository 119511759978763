@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}


@media only screen and (max-width: 768px) {
    .crisp-client {
      display: none;
    }
  }

  .go3958317564{
    font-size: 0.8rem;
  }


  ::-webkit-scrollbar-track
{
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
	--tw-gradient-from: #60a5fa var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

::-webkit-scrollbar
{
	width: 15px;
	/* background-color: #4188f5; */
}

::-webkit-scrollbar-thumb
{
	background-color: #fff;
  width: 12px;
	border-radius: 10px;
}

.chat-cont{
  mask-image: linear-gradient(to bottom, white, white, white, white, white,white,white,white, transparent) !important;
}